<template>
	<transition name='fade'>
		<section v-show='!loading' class='notification-task'>
			<section class='link-container'>
				<StsAccordion :title='title'>
					<section>
						<StsButton
							title='Click here to have someone reach out to schedule your closing!'
							type='text'
							buttonType='button'
							alternative
							small
							noIcon
							@click='handleSubmit'
						/>
					</section>
				</StsAccordion>
			</section>
		</section>
	</transition>
</template>

<script>
	import { signingAPI } from '@/api';
	import { StsAccordion, StsButton } from '@/components/SwitchThink';
	import showSpinner from '@/mixins/showSpinner';
	import { ruleBuilder, toastConfig } from '@/utils';
	import Component, { mixins } from 'vue-class-component';
	import { createToastInterface } from 'vue-toastification';

	const toast = createToastInterface(toastConfig);
	ruleBuilder(['required', 'digits']);

	@Component({
		components: {
			StsAccordion,
			StsButton
		},
		props: {
			moduleName: {
				type: String,
				required: true
			},
			/** @type {task} */
			task: {
				type: Object,
				required: true
			}
		}
	})
	class ScheduleClosingTask extends mixins(showSpinner) {
		// Data properties
		loading = false;
		title = 'Schedule Closing';

		async created() {
		}

		async handleSubmit() {
			try {
				this.showSpinner({ submitting: true });

                await signingAPI.createSigning(this.task.id);

				return this.$emit('submit');
			} catch (error) {
				this.showSpinner({ submitting: false });

				toast.error(error);
			}
		}
	}

    export default ScheduleClosingTask;
</script>
