<template>
    <transition name="fade">
        <section v-show="!loading" class="notification-task">
            <section class="link-container">
                <StsAccordion :title="title">
                    <ValidationObserver
                        ref="observer"
                        v-slot="{ invalid }"
                        tag="form"
                        @submit.prevent="handleSubmit"
                    >
                        <VehicleDetails
                            :collateral.sync="collateral"
                            :collateral-valuation-type="collateralValuationType"
                            @all-options-selected-change="onOptionsChange"
                            @inner-details-change="onVehicleDetailsChange"
                        />

                        <StsButton
                            :disabled="invalid || submitting || !allOptionsSelected"
                            button-type="submit"
                            title="SUBMIT"
                        />
                    </ValidationObserver>
                </StsAccordion>
            </section>
        </section>
    </transition>
</template>

<script>
    import { collateralAPI } from "@/api";
    import { VehicleDetails } from "@/components";
    import { StsAccordion, StsButton } from "@/components/SwitchThink";
    import { CollateralValuationType } from "@/constants";
    import { collateralValuation, showSpinner } from "@/mixins";
    import { collateralOptions } from "@/store/modules";
    import { ruleBuilder, toastConfig } from "@/utils";
    import { ValidationObserver } from "vee-validate";
    import { createToastInterface } from "vue-toastification";
    import { mapActions } from "vuex";

    const toast = createToastInterface(toastConfig);
    ruleBuilder(["required", "digits", "min_value"]);

    export default {
        name: "AddBoatCollateralTask",
        components: {
            StsAccordion,
            StsButton,
            ValidationObserver,
            VehicleDetails,
        },
        mixins: [collateralValuation, showSpinner],
        props: {
            moduleName: {
                type: String,
                required: true,
            },
            /** @type {task} */
            task: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                collateral: {
                    hullId: null,
                    make: null,
                    model: null,
                    vehicleType: null,
                    year: null,
                },
                allOptionsSelected: false,
                collateralValuationType: null,
                loading: false,
                title: "Add Boat Collateral",
            };
        },
        async created() {
            try {
                this.showSpinner({ loading: true });

                if (!this.$store.hasModule("collateralOptions")) {
                    this.$store.registerModule(
                        "collateralOptions",
                        collateralOptions
                    );
                }

                this.collateralValuationType = CollateralValuationType.MARINE;

                const applicationId = this.task.applicationId;
                const currentCollateral =
                    await collateralAPI.getBoatLoanApplicationCollateral(
                        applicationId
                    );

                if (currentCollateral && currentCollateral.boatCollateral) {
                    this.collateral.hullId =
                        currentCollateral.boatCollateral.hullId;
                    this.collateral.make =
                        currentCollateral.boatCollateral.make;
                    this.collateral.model =
                        currentCollateral.boatCollateral.model;
                    this.collateral.year =
                        currentCollateral.boatCollateral.year;

                    if (currentCollateral.boatCollateral.vehicleType > 0) {
                        this.collateral.vehicleType =
                            currentCollateral.boatCollateral.vehicleType;
                    }
                }

                this.showSpinner({ loading: false });
            } catch (error) {
                this.showSpinner({ loading: false });
                toast.error(error);
            }
        },
        methods: {
            ...mapActions("collateralOptions", [
                "getYearOptionsAsync",
                "getMakeOptionsAsync",
                "getModelOptionsAsync",
                "getTrimOptionsAsync",
            ]),
            onOptionsChange(newValue) {
                this.allOptionsSelected = newValue;
            },
            onVehicleDetailsChange(vehicleDetails) {
                this.collateral.hullId = vehicleDetails.hullId;
                this.collateral.make = vehicleDetails.make;
                this.collateral.model = vehicleDetails.model;
                this.collateral.vehicleType = vehicleDetails.vehicleType;
                this.collateral.year = vehicleDetails.year;
            },
            async handleSubmit() {
                try {
                    this.showSpinner({ submitting: true });

                    /** @type {autoLoanApplicationUpdateViewModelRequest} */
                    const request = {
                        applicationId: this.task.applicationId,
                        taskId: this.task.id,
                        collateral: this.collateral,
                        collateralValuationType: this.collateralValuationType,
                    };

                    await this.submitCollateral(request);

                    this.showSpinner({ loading: false });

                    return this.$emit("submit");
                } catch (error) {
                    this.showSpinner({ submitting: false });

                    return toast.error(error);
                }
            },
        },
    };
</script>
