<template>
	<transition name="fade">
		<section v-show="!loading" class="notification-task">
			<section class="link-container">
				<StsAccordion :title="title">
					<ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="handleSubmit()">
						<StsVInput
							v-model="collateral.street"
							:disabled="submitting"
							label="ADDRESS"
							type="text"
							validationRules="required"
						/>

						<StsVInput
							v-model="collateral.city"
							:disabled="submitting"
							label="CITY"
							type="text"
							validationRules="required"
						/>

						<StsVInput
							v-model="collateral.state"
							:disabled="submitting"
							label="STATE"
							type="text"
							validationRules="required"
						/>

						<StsVInput
							v-model="collateral.postalCode"
							:disabled="submitting"
							label="ZIP CODE"
							type="tel"
							:validationRules="{ required: true, regex: /^[0-9]{5}([- /]?[0-9]{4})?$/ }"
							:mask="['#####', '#####-####']"
						/>

						<StsButton :disabled="invalid || submitting" button-type="submit" title="SUBMIT" />
					</ValidationObserver>
				</StsAccordion>
			</section>
		</section>
	</transition>
</template>

<script>
	import { collateralAPI } from '@/api';
	import { StsAccordion, StsButton } from '@/components/SwitchThink';
	import { StsVInput, StsVSelect } from '@/components/SwitchThink/form';
	import showSpinner from '@/mixins/showSpinner';
	import { ruleBuilder, toastConfig } from '@/utils';
	import { ValidationObserver } from 'vee-validate';
	import Component, { mixins } from 'vue-class-component';
	import { createToastInterface } from 'vue-toastification';

	const toast = createToastInterface(toastConfig);
	ruleBuilder(['regex', 'required']);

	@Component({
		components: {
			StsAccordion,
			StsButton,
			StsVInput,
			StsVSelect,
			ValidationObserver
		},
		props: {
			moduleName: {
				type: String,
				required: true
			},
			/** @type {task} */
			task: {
				type: Object,
				required: true
			}
		}
	})
	export default class AddHomeCollateralTask extends mixins(showSpinner) {
		// Data properties
		loading = false;
		title = 'Add Collateral';
		/** @type {homeLoanCollateral} */
		collateral = {
			street: null,
			city: null,
			state: null,
			postalCode: null
		};

		async created() {
			try {
				this.showSpinner({ loading: true });

				this.showSpinner({ loading: false });
			} catch (error) {
				this.showSpinner({ loading: false });
				toast.error(error);
			}
		}

		async handleSubmit() {
			const isValid = await this.$refs.observer.validate();

			if (!isValid) {
				return toast.error('Collateral information is invalid.');
			}

			try {
				this.showSpinner({ submitting: true });

				return await this.submitCollateral();
			} catch (error) {
				this.showSpinner({ submitting: false });

				return toast.error(error);
			}
		}

		async submitCollateral() {
			/** @type {homeLoanApplicationUpdateViewModelRequest} */
			const request = {
				applicationId: this.task.applicationId,
				taskId: this.task.id,
				collateral: this.collateral
			};

			await collateralAPI.createHomeCollateral(request);

			this.showSpinner({ submitting: false });

			return this.$emit('submit');
		}
	}
</script>
