<template>

  <transition name="fade">

    <section v-show="!loading" class="notification-task">

      <section class="link-container">

        <StsAccordion :title="model.title">

          <StsButton
            title="Acknowledged"
            type="text"
            buttonType="button"
            alternative
            small
            noIcon
            @click="acknowledgeNotification" />

        </StsAccordion>

      </section>

    </section>

  </transition>

</template>

<script>

import { StsAccordion, StsButton } from "@/components/SwitchThink";
import showSpinner from "@/mixins/showSpinner";
import { toastConfig } from "@/utils";
import { createToastInterface } from "vue-toastification";

const toast = createToastInterface(toastConfig);

export default {
  name: "NotificationTask",
  components: {
    StsButton,
    StsAccordion
  },
  mixins: [showSpinner],
  props: {
    moduleName: {
      type: String,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  async created() {
    try {

      this.showSpinner({ loading: true });

      return this.showSpinner({ loading: false });

    } catch (error) {

      this.showSpinner({ submitting: false });
      return toast.error(error);

    }
  },
  methods: {
    async acknowledgeNotification() {

      this.showSpinner({ loading: true });

      alert("Notification Acknowledged");

      this.showSpinner({ submitting: false });

      return this.$emit("submit");
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-task {


}
</style>
