<template>
    <transition name="fade">
        <section v-show="!loading" class="notification-task">
            <section class="link-container">
                <StsAccordion :title="title">
                    <section>
                        <p v-html="epoaRequiredMessage" />
                        <br />
                        <StsButton
                            button-type="submit"
                            title="ALL BORROWERS HAVE AN ACTIVE AZ MVD NOW ACCOUNT"
                            @click="handleAcknowledgement"
                        />
                    </section>
                </StsAccordion>
            </section>
        </section>
    </transition>
</template>

<script>
    import { StsAccordion, StsButton } from "@/components/SwitchThink";
    import { UiLanguageType, UiTextComponentType } from "@/constants";
    import { ruleBuilder, toastConfig } from "@/utils";
    import { StsVTextArea } from "@/components/SwitchThink/form";
    import { ValidationObserver } from "vee-validate";
    import { createToastInterface } from "vue-toastification";
    import { additionalFlagsAPI } from "@/api";
    import showSpinner from "@/mixins/showSpinner";
    import { mapActions } from "vuex";

    ruleBuilder(["required", "digits", "max"]);
    const toast = createToastInterface(toastConfig);

    export default {
        name: "EpoaRequiredTask",
        components: {
            StsAccordion,
            StsButton,
            StsVTextArea,
            ValidationObserver,
        },
        mixins: [showSpinner],
        props: {
            moduleName: {
                type: String,
                required: true,
            },
            /** @type {task} */
            task: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                title: "Electronic Power of Attorney(s) required",
                epoaRequiredMessage: "",
            };
        },
        async created() {
            try {
                this.showSpinner({ loading: true });

                const request = {
                    uiLanguageType: UiLanguageType.ENGLISH,
                    uiTextComponentType:
                        UiTextComponentType.EPOA_REQUIRED_MESSAGE,
                };

                this.epoaRequiredMessage = await this.getUiTextValue(request);

                this.showSpinner({ loading: false });
            } catch (error) {
                this.showSpinner({ loading: false });
                toast.error(error);
            }
        },
        methods: {
            ...mapActions("uiTextValues", ["getUiTextValue"]),
            async handleAcknowledgement() {
                try {
                    this.showSpinner({ submitting: true });

                    /** @type {epoaRequiredFlagUpdateViewModelRequest} */
                    const request = {
                        taskId: this.task.id,
                    };

                    await additionalFlagsAPI.updateEpoaRequiredFlag(request);

                    this.showSpinner({ submitting: false });

                    return this.$emit("submit");
                } catch (error) {
                    this.showSpinner({ submitting: false });
                    toast.error(error);
                }
            },
        },
    };
</script>
