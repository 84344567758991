<template>
	<transition-group name="fade">
		<DuplicateUploadModal
			:title="ModalTitle"
			:message="duplicateMessage"
			cancelText="Refresh Documents"
			:secondaryMessage="secondaryMessage"
			@close="cancelUpload"
			:preventClose="preventCloseModal"
			isSmall
			key="modal"
			v-if="showDuplicateModal"
		/>
		<section v-show="!loading" class="notification-task" key="mainsection">
			<section class="link-container">
				<StsAccordion :title="title">
					<fieldset class="sellerInfo">
						<legend>WHICH OPTION ARE YOU PURCHASING FROM</legend>
						<StsVRadioGroup
							v-model="sellerInfoType"
							:options="[
								{ label: 'Dealer', value: SellerType.DEALER },
								{ label: 'Private Party', value: SellerType.PRIVATE_PARTY }
							]"
							is-horizontal
							name="Seller Info"
						/>
					</fieldset>

					<fieldset v-if="isDealer">
						<legend>UPLOAD PURCHASE ORDER</legend>
						<section class="upload-container">
							<StsUpload
								:allowMultiple="true"
								:name="`upload-${moduleName}`"
								acceptedFiles="bmp png jpeg pdf tiff"
								:maxUploadSizeMB="22"
								@save="handleUpload"
							/>
						</section>

						<section class="note-container">
							<p>
								<strong>Please note</strong>
							</p>
							<p>
								Only the following file types are supported: <strong>BMP, PNG, JPG, PDF, TIF</strong>.
							</p>
							<p>The maximum file upload size is <strong>22MB</strong>.</p>
						</section>
					</fieldset>

					<ValidationObserver
						v-if="isPrivateParty"
						ref="observer"
						v-slot="{ invalid }"
						tag="form"
						@submit.prevent="handleSubmit()"
					>
						<StsVInput
							v-model="seller.name"
							:disabled="submitting"
							label="SELLER NAME"
							type="text"
							validationRules="required"
						/>

						<StsVInput
							v-model="seller.phoneNumber"
							:disabled="submitting"
							label="SELLER PHONE NUMBER"
							mask="(###) ###-####"
							type="tel"
							validationRules="required"
						/>

						<StsVInput
							v-model="seller.email"
							:disabled="submitting"
							label="SELLER EMAIL (OPTIONAL)"
							type="text"
						/>

						<StsVInput
							v-model="lien.name"
							:disabled="submitting"
							label="SELLER LIENHOLDER NAME (OPTIONAL)"
							type="text"
						/>

						<StsVInput
							v-model="lien.accountNumber"
							:disabled="submitting"
							label="SELLER LIENHOLDER ACCOUNT NUMBER (OPTIONAL)"
							type="text"
						/>

						<StsButton :disabled="invalid || submitting" button-type="submit" title="SUBMIT" />
					</ValidationObserver>
				</StsAccordion>
			</section>
		</section>
	</transition-group>
</template>

<script>
	import { documentAPI, sellerAPI } from '@/api';
	import { DuplicateUploadModal } from '@/components';
	import { StsAccordion, StsButton } from '@/components/SwitchThink';
	import { StsUpload, StsVInput, StsVRadioGroup } from '@/components/SwitchThink/form';
	import { SellerType } from '@/constants';
	import showSpinner from '@/mixins/showSpinner';
	import { ruleBuilder, toastConfig } from '@/utils';
	import { ValidationObserver } from 'vee-validate';
	import Component, { mixins } from 'vue-class-component';
	import { createToastInterface } from 'vue-toastification';	
	import axios from 'axios';
	const toast = createToastInterface(toastConfig);
	ruleBuilder(['required', 'digits']);

	@Component({
		components: {
			StsAccordion,
			StsButton,
			StsVInput,
			StsUpload,
			StsVRadioGroup,
			ValidationObserver,
			DuplicateUploadModal
		},
		props: {
			moduleName: {
				type: String,
				required: true
			},
			/** @type {task} */
			task: {
				type: Object,
				required: true
			}
		}
	})
	class SellerInfoTask extends mixins(showSpinner) {
		// Data properties
		loading = false;
		title = 'Seller Info';
		/** @type: seller */
		seller = {
			sellerConfigurationTypeId: SellerType.NONE,
			name: '',
			phoneNumber: '',
			email: ''
		};
		lien = {
			name: '',
			phoneNumber: '',
			accountNumber: ''
		};
		sellerInfoType = 0;

		get isDealer() {
			return this.sellerInfoType === SellerType.DEALER;
		}

		get isPrivateParty() {
			return this.sellerInfoType === SellerType.PRIVATE_PARTY;
		}

		data() {
			return {
				SellerType: undefined,
				showDuplicateModal: false,
				ModalTitle: 'Duplicate Detected',
				secondaryMessage: '',
				duplicateMessage:'We already have this/these documents on file.  Select a new file or click to refresh the page.',
				preventCloseModal: true
			};
		}

		async created() {
			try {
				this.showSpinner({ loading: true });
				const {
					data: { defaultPhoneNumber }
				} = await axios.get('/api/Configuration/DefaultPhoneNumber');
				this.secondaryMessage = "If you continue to receive this message, please contact your loan specialist directly or reach us at " + defaultPhoneNumber+".";
				
				this.SellerType = SellerType;

                const applicationId = this.task.applicationId;
                const currentSellerInfo = await sellerAPI.getSellerInfo(applicationId);

				if (currentSellerInfo) {
					if (currentSellerInfo.seller) {
						this.seller.name = currentSellerInfo.seller.name;
						this.seller.phoneNumber = currentSellerInfo.seller.phoneNumber;
						this.seller.email = currentSellerInfo.seller.email;
					}
					if (currentSellerInfo.lien) {
						this.lien.name = currentSellerInfo.lien.name;
						this.lien.phoneNumber = currentSellerInfo.lien.phoneNumber;
						this.lien.accountNumber = currentSellerInfo.lien.accountNumber;
                    }
                }

				this.showSpinner({ loading: false });
			} catch (error) {
				this.showSpinner({ loading: false });
				toast.error(error);
			}
		}

		async handleSubmit() {
			const isValid = await this.$refs.observer.validate();

			if (!isValid) {
				return toast.error('Seller information is invalid.');
			}

			try {
				this.showSpinner({ submitting: true });

				return await this.submitSeller(SellerType.PRIVATE_PARTY);
			} catch (error) {
				this.showSpinner({ submitting: false });

				return toast.error(error);
			}
		}
		async cancelUpload() {
			console.log('CancelingUpload');
			this.preventCloseModal = false;
			this.$emit('refresh');
			this.reload = false;
		}
		async handleUpload({ data, fileCount }) {
			try {
				this.showSpinner({ submitting: true });

				const { posted, resultType } = await documentAPI.uploadTaskDocuments(
					this.task.applicationId,
					this.task.id,
					data
				);
				console.log('Result type: ' + resultType);
				this.showSpinner({ submitting: false });
				//If documents were handled properly on backend
				if (posted) {
					//1 no Duplicates
					if (resultType === 1 || resultType === 2) {
						await this.submitSeller(SellerType.DEALER);
						//3 all Duplicates
					} else if (resultType === 3) {
						//Do we want them to upload more files or just refresh the page??
						this.duplicateMessage = 'We already have these documents on file. Click to refresh the page.';
						this.showDuplicateModal = true;
						//0 Stip already complete
					} else if (resultType === 4) {
						//Do we want them to upload more files or just refresh the page??
						this.ModalTitle = 'Task Completed';
						this.duplicateMessage = 'You have already completed this task. Click to refresh the page.';
						this.showDuplicateModal = true;
						//0 none
					} else {
						return toast.error('Error encountered while uploading, please try again.');
					}
				} else {
					return toast.error('Error encountered while uploading, please try again.');
				}
			} catch (error) {
				this.showSpinner({ submitting: false });

				return toast.error(error);
			}
		}

		async submitSeller(sellerType) {
			this.showSpinner({ submitting: true });
			this.seller.sellerTypeConfigurationId = sellerType;

			/** @type {sellerCreateViewModelRequest} */
			const request = {
				taskId: this.task.id,
				seller: this.seller,
				lien: this.lien
			};

			await sellerAPI.createSeller(request);

			this.showSpinner({ submitting: false });

			return this.$emit('submit');
		}
	}

	export default SellerInfoTask;
</script>

<style lang="scss" scoped>
	fieldset {
		border: none;
		padding: 0;
		margin: 0;

		&:not(:last-child) {
			padding-bottom: 1rem;
		}

		legend {
			color: var(--primary);
			font-size: 0.9rem;
			margin-bottom: 0.5rem;
			font-weight: 400;
		}
	}

	.note-container p {
		font-size: 0.7rem;
	}
</style>
