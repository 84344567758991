<template>
    <transition name="fade">
        <section v-show="!loading" class="application-tasks">
            <StsSectionHeader title="YOUR ACTION ITEMS" />
            <p>
                All Action Items must be submitted before we can process your loan.
            </p>
            <p style="margin-top:0.5rem">
                You may upload multiple documents in an Action Item if needed, but please submit information 
                for each Action Item to move your loan application into processing.
            </p>

            <section class="task-section">
                <div
                    v-for="activeStipulation in activeStipulations"
                    :id="activeStipulation.moduleName"
                    :key="`active-stipulation-${activeStipulation.id}`"
                    class="task"
                >
                    <UploadTask
                        v-if="isUploadTask(activeStipulation)"
                        :key="`upload-${activeStipulation.id}`"
                        :module-name="activeStipulation.moduleName"
                        :model="activeStipulation"
                        @submit="submitStipulation(activeStipulation)"
                        @refresh="reloadStipulations()"
                    />

                    <NotificationTask
                        v-else
                        :key="`notification-${activeStipulation.id}`"
                        :module-name="activeStipulation.moduleName"
                        :model="activeStipulation"
                        @submit="submitStipulation(activeStipulation)"
                    />
                </div>

                <div
                    v-for="activeTask in activeTasks"
                    :id="activeTask.moduleName"
                    :key="`active-task-${activeTask.id}`"
                    class="task"
                >
                    <AddAutoCollateralTask
                        v-if="activeTask.taskType === taskType.AUTO_COLLATERAL"
                        :key="`addAutoCollateral-${activeTask.id}`"
                        :module-name="activeTask.moduleName"
                        :task="activeTask"
                        @submit="reloadTasks"
                    />

                    <AddBoatCollateralTask
                        v-if="activeTask.taskType === taskType.BOAT_COLLATERAL"
                        :key="`addBoatCollateral-${activeTask.id}`"
                        :module-name="activeTask.moduleName"
                        :task="activeTask"
                        @submit="reloadTasks"
                    />

                    <AddHomeCollateralTask
                        v-if="activeTask.taskType === taskType.ADD_COLLATERAL"
                        :key="`addHomeCollateral-${activeTask.id}`"
                        :module-name="activeTask.moduleName"
                        :task="activeTask"
                        @submit="reloadTasks"
                    />

                    <AddMotorcycleCollateralTask
                        v-if="activeTask.taskType === taskType.MOTORCYCLE_COLLATERAL"
                        :key="`addMotorcycleCollateral-${activeTask.id}`"
                        :module-name="activeTask.moduleName"
                        :task="activeTask"
                        @submit="reloadTasks"
                    />

                    <AddRVCollateralTask
                        v-if="activeTask.taskType === taskType.RV_COLLATERAL"
                        :key="`addRVCollateral-${activeTask.id}`"
                        :module-name="activeTask.moduleName"
                        :task="activeTask"
                        @submit="reloadTasks"
                    />

                    <SellerInfoTask
                        v-if="activeTask.taskType === taskType.SELLER_INFORMATION"
                        :key="`sellerInfo-${activeTask.id}`"
                        :module-name="activeTask.moduleName"
                        :task="activeTask"
                        @submit="reloadTasks"						
                        @refresh="reloadTasks"
                    />

                    <ProofOfInsuranceTask
                        v-if="activeTask.taskType === taskType.PROOF_OF_INSURANCE"
                        :key="`proofOfInsurance-${activeTask.id}`"
                        :task="activeTask"
                        :module-name="activeTask.moduleName"
                        @submit="reloadTasks"
                    />

                    <LienholderInfoTask
                        v-if="activeTask.taskType === taskType.LIENHOLDER_INFORMATION"
                        :key="`lienholderInfo-${activeTask.id}`"
                        :module-name="activeTask.moduleName"
                        :task="activeTask"
                        @submit="reloadTasks"
                    />

                    <ScheduleClosingTask
                        v-if="activeTask.taskType === taskType.SCHEDULE_CLOSING"
                        :key="`scheduleClosing-${activeTask.id}`"
                        :module-name="activeTask.moduleName"
                        :task="activeTask"
                        @submit="reloadTasks"
                    />

                    <EpoaRequiredTask
                        v-if="activeTask.taskType === taskType.EPOA_REQUIRED"
                        :key="`epoaRequired-${activeTask.id}`"
                        :module-name="activeTask.moduleName"
                        :task="activeTask"
                        @submit="reloadTasks" />

                    <!--<NoteTask
                        v-if="activeTask.taskType === taskType.NOTE"
                        :key="`note-${activeTask.id}`"
                        :module-name="activeTask.moduleName"
                        :task="activeTask"
                        @submit="reloadTasks"
                    />-->
                </div>
            </section>

            <StsSectionHeader title="ACTION ITEMS YOU HAVE SUBMITTED" />

            <section class="task-section">
                <div
                    v-for="satisfiedStipulation in satisfiedStipulations"
                    :id="satisfiedStipulation.moduleName"
                    :key="`completed-stipulation-${satisfiedStipulation.id}`"
                    class="completed-task"
                >
                    <CompletedTask
                        :key="`upload${satisfiedStipulation.id}`"
                        :module-name="satisfiedStipulation.moduleName"
                        :model="satisfiedStipulation"
                    />
                </div>

                <div
                    v-for="satisfiedTask in satisfiedTasks"
                    :id="satisfiedTask.moduleName"
                    :key="`completed-task-${satisfiedTask.id}`"
                    class="completed-task"
                >
                    <CompletedTask
                        :key="`sellerInfo-${satisfiedTask.id}`"
                        :module-name="satisfiedTask.moduleName"
                        :model="satisfiedTask"
                    />
                </div>

                <div
                    v-for="submittedNote in submittedNotes"
                    :id="submittedNote.moduleName"
                    :key="`completed-task-${submittedNote.id}`"
                    class="completed-task"
                >
                    <CompletedTask
                        :key="`sellerInfo-${submittedNote.id}`"
                        :module-name="submittedNote.moduleName"
                        :model="submittedNote"
                    />
                </div>
            </section>
        </section>
    </transition>
</template>

<script>
    import _cloneDeep from 'lodash.clonedeep';
    import { createToastInterface } from 'vue-toastification';

    import { stipulationAPI } from '@/api';
    import { StsSectionHeader } from '@/components/SwitchThink';
    import { StipulationStatus, StipulationType, TaskStatus, TaskType } from '@/constants';
    import showSpinner from '@/mixins/showSpinner';
    import { noteTemplate, stipulationTemplate, taskTemplate } from '@/store/modules/templates';
    import { dates, toastConfig } from '@/utils';
    import {
        AddAutoCollateralTask,
        AddBoatCollateralTask,
        AddHomeCollateralTask,
        AddMotorcycleCollateralTask,
        AddRVCollateralTask,
        CompletedTask,
        EpoaRequiredTask,
        LienholderInfoTask,
        NotificationTask,
        ProofOfInsuranceTask,
        ScheduleClosingTask,
        SellerInfoTask,
        UploadTask
    } from '@/views/Partials/Tasks';

    const toast = createToastInterface(toastConfig);

    export default {
        name: 'ApplicationTasks',
        components: {
            AddAutoCollateralTask,
            AddBoatCollateralTask,
            AddHomeCollateralTask,
            AddMotorcycleCollateralTask,
            AddRVCollateralTask,
            EpoaRequiredTask,
            StsSectionHeader,
            UploadTask,
            NotificationTask,
            CompletedTask,
            SellerInfoTask,
            ProofOfInsuranceTask,
            LienholderInfoTask,
            ScheduleClosingTask
        },
        mixins: [showSpinner],
        props: {
            moduleName: {
                type: String,
                required: true
            },
            model: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                notes: [],
                stipulations: [],
                tasks: [],
                satisfiedStipulationStatuses: [
                    StipulationStatus.WAIVED,
                    StipulationStatus.SUBMITTED,
                    StipulationStatus.SATISFIED,
                    StipulationStatus.ARCHIVED
                ]
            };
        },
        computed: {
            activeStipulations() {
                let result = [];

                if (this.stipulations !== null && this.stipulations.length > 0) {
                    result = this.stipulations
                        .filter(x => x.status === StipulationStatus.ACTIVE || x.status === StipulationStatus.INCOMPLETE)
                        .map(active => active);
                }

                return result;
            },
            satisfiedStipulations() {
                let result = [];

                if (this.stipulations !== null && this.stipulations.length > 0) {
                    result = this.stipulations
                        .filter(x => this.satisfiedStipulationStatuses.some(status => status === x.status))
                        .map(satisfied => satisfied);
                }

                return result;
            },
            activeTasks() {
                let result = [];

                if (this.tasks !== null && this.tasks.length > 0) {
                    result = this.tasks.filter(x => x.taskStatus === TaskStatus.ACTIVE);
                }

                return result;
            },
            satisfiedTasks() {
                let result = [];

                if (this.tasks !== null && this.tasks.length > 0) {
                    result = this.tasks
                        .filter(x => x.taskStatus === TaskStatus.COMPLETE)
                        .map(task => ({ ...task, title: this.getTaskTitle(task) }));
                }

                return result;
            },
            submittedNotes() {
                let result = [];

                if (this.notes !== null && this.notes.length > 0) {
                    result = this.notes.map(note => ({
                        ...note,
                        title: `Comment submitted ${dates.getDateTimeString(note.createdDate)}`
                    }));
                }

                return result;
            },
            taskType() {
                return {
                    NONE: TaskType.NONE,
                    SELLER_INFORMATION: TaskType.SELLER_INFORMATION,
                    PROOF_OF_INSURANCE: TaskType.PROOF_OF_INSURANCE,
                    LIENHOLDER_INFORMATION: TaskType.LIENHOLDER_INFORMATION,
                    SCHEDULE_CLOSING: TaskType.SCHEDULE_CLOSING,
                    BOAT_COLLATERAL: TaskType.BOAT_COLLATERAL,
                    AUTO_COLLATERAL: TaskType.AUTO_COLLATERAL,
                    RV_COLLATERAL: TaskType.RV_COLLATERAL,
                    MOTORCYCLE_COLLATERAL: TaskType.MOTORCYCLE_COLLATERAL,
                    NOTE: TaskType.NOTE,
                    EPOA_REQUIRED: TaskType.EPOA_REQUIRED
                };
            }
        },
        async created() {
            try {
                this.showSpinner({ loading: true });

                this.notes = await this.$store.dispatch(`${this.moduleName}/loadNotes`);
                this.stipulations = await this.$store.dispatch(`${this.moduleName}/loadStipulations`);
                this.tasks = await this.$store.dispatch(`${this.moduleName}/loadTasks`);

                if (this.notes !== null) {
                    for (const note of this.notes) {
                        if (!this.$store.hasModule(note.moduleName)) {
                            await this.$store.registerModule(note.moduleName, _cloneDeep(noteTemplate));
                        }
                        await this.$store.dispatch(`${note.moduleName}/setNote`, note);
                    }
                }

                if (this.stipulations !== null && this.stipulations.length > 0) {
                    for (const item of this.stipulations) {
                        if (!this.$store.hasModule(item.moduleName)) {
                            await this.$store.registerModule(item.moduleName, _cloneDeep(stipulationTemplate));
                        }
                        await this.$store.dispatch(`${item.moduleName}/setStipulation`, item);
                    }
                }

                if (this.tasks !== null) {
                    for (const task of this.tasks) {
                        if (!this.$store.hasModule(task.moduleName)) {
                            await this.$store.registerModule(task.moduleName, _cloneDeep(taskTemplate));
                        }
                        await this.$store.dispatch(`${task.moduleName}/setTask`, task);
                    }
                }

                return this.showSpinner({ loading: false });
            } catch (error) {
                this.showSpinner({ submitting: false });
                return toast.error(error);
            }
        },
        methods: {
            isUploadTask(stipulation) {
                return stipulation.type === StipulationType.DOCUMENT_UPLOAD;
            },
            getTaskTitle(task) {
                return task.taskTitle ? task.taskTitle : "Completed Task";
            },
            async reloadStipulations() {
                this.showSpinner({ submitting: true });

                try {
                    this.stipulations = await this.$store.dispatch(`${this.moduleName}/loadStipulations`);

                    if (this.stipulations !== null && this.stipulations.length > 0) {
                        for (const item of this.stipulations) {
                            if (!this.$store.hasModule(item.moduleName)) {
                                const template = _cloneDeep(stipulationTemplate);
                                await this.$store.registerModule(item.moduleName, template);
                                await this.$store.dispatch(`${item.moduleName}/setStipulation`, item);
                            }
                        }
                    }

                    this.showSpinner({ submitting: false });
                    return this.$emit('submit');
                } catch (error) {
                    this.showSpinner({ submitting: false });

                    return toast.error(error);
                }
            },
            async reloadTasks() {
                this.showSpinner({ submitting: true });

                try {
                    this.tasks = await this.$store.dispatch(`${this.moduleName}/loadTasks`);

                    if (this.tasks !== null) {
                        for (const task of this.tasks) {
                            if (!this.$store.hasModule(task.moduleName)) {
                                const template = _cloneDeep(taskTemplate);
                                await this.$store.registerModule(task.moduleName, template);
                                await this.$store.dispatch(`${task.moduleName}/setTask`, task);
                            }
                        }
                    }

                    this.notes = await this.$store.dispatch(`${this.moduleName}/loadNotes`);

                    if (this.notes !== null) {
                        for (const note of this.notes) {
                            if (!this.$store.hasModule(note.moduleName)) {
                                const template = _cloneDeep(noteTemplate);
                                await this.$store.registerModule(note.moduleName, template);
                                await this.$store.dispatch(`${note.moduleName}/setNote`, note);
                            }
                        }
                    }

                    this.showSpinner({ submitting: false });
                    return this.$emit('submit');
                } catch (error) {
                    this.showSpinner({ submitting: false });

                    return toast.error(error);
                }
            },
            async submitStipulation(stipulation) {
                this.showSpinner({ submitting: true });

                try {
                    /**
                     * @typedef {object} stipulation Stipulation
                     * @property {string} stipulationId Vendor Stipulation Id
                     * @property {string} stipulationToken Stipulation Token
                     * @property {string} title Stipulation Title
                     * @property {number} type Stipulation Type
                     * @property {number} status Stipulation Status
                     */

                    /**
                     * @typedef {object} stipulationUpdateViewModelRequest
                     * @property {number} applicationId Application ID
                     * @property {stipulation} stipulation Stipulation
                     */

                    /** @type {stipulationUpdateViewModelRequest} */
                    const request = {
                        applicationId: stipulation.applicationId,
                        stipulation: {
                            id: stipulation.id,
                            stipulationId: stipulation.stipulationId,
                            stipulationToken: stipulation.stipulationToken,
                            title: stipulation.title,
                            type: stipulation.type,
                            status: stipulation.status
                        }
                    };
                    const result = await stipulationAPI.updateStipulation(request);

                    if (!result.updated) {
                        return toast.error('There was an error submitting, please try again.');
                    }

                    return this.reloadStipulations();
                } catch (error) {
                    this.showSpinner({ submitting: false });

                    return toast.error(error);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .task:not(:last-child) {
        margin-bottom: 0.25rem;
    }
    .completed-task:not(:last-child) {
        margin-bottom: 1rem;
    }
    .task-section:not(:last-child) {
        margin-bottom: 1rem;
    }
    .application-tasks {
        .task {
            margin: 1em;
        }
    }
</style>
