<template>
	<transition name="fade">
		<section v-show="!loading" class="notification-task">
			<section class="link-container">
				<StsAccordion :title="title">
					<ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="handleSubmit()">
						<StsCard v-if="isValuationEnabled" style="margin-bottom:1.5rem" noShadow>
							<StsVSelect
								v-if="showYears"
								v-model.number="collateral.year"
								:disabled="submitting"
								:options="options.years"
								label="Year"
								placeholder="Please select the year"
								validationRules="required"
								@input="handleInput(CollateralOptionType.YEAR)"
							/>

							<StsVSelect 
								v-if="showMakes"
								v-model="collateral.make"
								:disabled="submitting"
								:options="options.makes"
								:placeholder="collateral.year ? 'Please select the make' : ''"
								label="Make"
								validationRules="required"
								@input="handleInput(CollateralOptionType.MAKE)"
							/>
							
							<StsVSelect
								v-if="showModels"
								v-model="collateral.model"
								:disabled="submitting"
								:options="options.models"
								:placeholder="collateral.make ? 'Please select the model' : ''"
								label="Model"
								validationRules="required"
								@input="handleInput(CollateralOptionType.MODEL)"
							/>

							<StsVSelect
                                v-if="showAdditionalFields"
								v-model="collateral.motorcycleType"
								:disabled="submitting"
								:options="motorcycleTypes"
								label="TYPE"
								validationRules="required"
							/>						

							<StsVInput
                                v-if="showAdditionalFields"
								v-model="collateral.mileage"
								:disabled="submitting"
								label="EXACT MILEAGE"
								type="tel"
								mask="######"
								validationRules="required"
							/>						

							<StsVInput 
                                v-if="showAdditionalFields"
                                v-model="collateral.vin" 
							    :disabled="submitting" 
							    label="VIN" 
							    type="text" 
							    validationRules="required"
							    forceCapitalization/>

							<StsVSelect
                                v-if="showAdditionalFields"
								v-model="collateral.fuelType"
								:disabled="submitting"
								:options="fuelTypes"
								label="FUEL TYPE"
								validationRules="required"
							/>

							<BaseIcon
								v-if="populatingOptions"
								height="1rem"
								name="populatingOptionsClock"
								viewBox="0 0 50 50"
								width="1rem"
							>
								<Clock />
							</BaseIcon>

						</StsCard>
						<StsCard v-if="!isValuationEnabled" style="margin-bottom: 1.5rem" noShadow>
							<StsVInput
								v-model="collateral.year"
								:disabled="submitting"
								label="YEAR"
								mask="####"
								type="tel"
								validationRules="required|digits:4|min_value:1900"
							/>

							<StsVInput
								v-model="collateral.make"
								:disabled="submitting"
								label="MAKE"
								type="text"
								validationRules="required"
							/>

							<StsVInput
								v-model="collateral.model"
								:disabled="submitting"
								label="MODEL"
								type="text"
								validationRules="required"
							/>

							<StsVSelect
								v-model="collateral.motorcycleType"
								:disabled="submitting"
								:options="motorcycleTypes"
								label="TYPE"
								validationRules="required"
							/>						

							<StsVInput
								v-model="collateral.mileage"
								:disabled="submitting"
								label="EXACT MILEAGE"
								type="tel"
								mask="######"
								validationRules="required"
							/>						

							<StsVInput 
								v-model="collateral.vin" 
								:disabled="submitting" 
								label="VIN" 
								type="text" 
								validationRules="required"
								forceCapitalization
							/>

							<StsVSelect
								v-model="collateral.fuelType"
								:disabled="submitting"
								:options="fuelTypes"
								label="FUEL TYPE"
								validationRules="required"
							/>

						</StsCard>
						<StsButton :disabled="invalid || submitting || !allOptionsSelected" button-type="submit" title="SUBMIT" />
					</ValidationObserver>
				</StsAccordion>
			</section>
		</section>
	</transition>
</template>

<script>
	import { collateralAPI } from '@/api';
	import { StsAccordion, StsButton, StsCard } from '@/components/SwitchThink';
	import { StsVInput, StsVSelect } from '@/components/SwitchThink/form';
	import { BaseIcon, Clock } from '@/components/SwitchThink/icons';
	import { CollateralOptionType, CollateralValuationType, ProductType } from '@/constants';
	import { collateralValuation, showSpinner } from '@/mixins';
    import { collateralOptions } from '@/store/modules';
	import { ruleBuilder, toastConfig } from '@/utils';
	import { ValidationObserver } from 'vee-validate';
	import Component, { mixins } from 'vue-class-component';
	import { createToastInterface } from 'vue-toastification';
    import { mapActions } from 'vuex';

	const toast = createToastInterface(toastConfig);
	ruleBuilder(['required', 'digits', 'min_value']);

	export default {
		name: 'AddMotorcycleCollateralTask',
        components: {
            BaseIcon,
            Clock,
			StsAccordion,
			StsButton,
            StsVSelect,
            StsCard,
			StsVInput,
			ValidationObserver
		},
        mixins: [collateralValuation, showSpinner],
		props: {
			moduleName: {
				type: String,
				required: true
			},
			task: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
                loading: false,
                title: 'Add Collateral',
                collateralValuationType: null,
                populatingOptions: false,
                collateral: {
                    year: null,
                    make: null,
                    model: null,
                    mileage: null,
                    vin: null,
                    motorcycleType: null,
                    fuelType: null
                },
                fuelTypes: [],
                motorcycleTypes: [],
                options: {
                    years: [],
                    makes: [],
                    models: [],
                }
			}
		},
		computed: {
            CollateralOptionType() {
                return CollateralOptionType;
            },
            allOptionsSelected() {
                if (!this.isValuationEnabled) {
                    return true;
				}

                return this.collateral.year && this.collateral.make && this.collateral.model;
            },
            isValuationEnabled() {
                return this.valuationEnabled(ProductType.MOTORCYCLE);
            },
            showMakes() {
                return this.options.makes.length;
            },
            showAdditionalFields() {
                return this.allOptionsSelected;
            },
            showModels() {
                return this.options.models.length && this.showMakes;
            },
            showYears() {
                return this.options.years.length;
            }
		},
		async created() {
            try {
                this.showSpinner({ loading: true });

                if (!this.$store.hasModule('collateralOptions')) {
                    this.$store.registerModule('collateralOptions', collateralOptions);
                }
                this.collateralValuationType = CollateralValuationType.MOTORCYCLE;

                const { fuelTypes, motorcycleTypes } = await collateralAPI.getMotorcycleCollateralOptions();

                this.fuelTypes = fuelTypes.map(type => ({
                    label: type.displayText,
                    value: type.id
                }));

                this.motorcycleTypes = motorcycleTypes.map(type => ({
                    label: type.displayText,
                    value: type.id
                }));

                /** @type {motorcycleLoanApplicationCollateralViewModelRequest} */
                const applicationId = this.task.applicationId;

                const currentCollateral = await collateralAPI.getMotorcycleLoanApplicationCollateral(applicationId);

                if (currentCollateral && currentCollateral.motorcycleCollateral) {
                    this.collateral.year = currentCollateral.motorcycleCollateral.year;
                    this.collateral.make = currentCollateral.motorcycleCollateral.make;
                    this.collateral.model = currentCollateral.motorcycleCollateral.model;
                    this.collateral.mileage = currentCollateral.motorcycleCollateral.mileage;
                    this.collateral.vin = currentCollateral.motorcycleCollateral.vin;

                    if (currentCollateral.motorcycleCollateral.motorcycleType > 0) {
                        this.collateral.motorcycleType = currentCollateral.motorcycleCollateral.motorcycleType;
                    }

                    if (currentCollateral.motorcycleCollateral.fuelType > 0) {
                        this.collateral.fuelType = currentCollateral.motorcycleCollateral.fuelType;
                    }
                }

                await this.initializeOptionsAsync();

                this.showSpinner({ loading: false });
            } catch (error) {
                this.showSpinner({ loading: false });
                toast.error(error);
            }
		},
        methods: {
            ...mapActions('collateralOptions', [
                'getYearOptionsAsync',
                'getMakeOptionsAsync',
                'getModelOptionsAsync',
                'getTrimOptionsAsync'
            ]),
            async initializeOptionsAsync() {
                try {
                    if (!this.isValuationEnabled) {
                        return;
                    }
                    // We always want to populate the years
                    await this.populateYearOptionsAsync();
                    // Let's populate the options using any values we already have
                    if (this.collateral.year) {
                        await this.populateMakeOptionsAsync(this.collateral.year);
                    }
                    if (this.collateral.make) {
                        await this.populateModelOptionsAsync(this.collateral.year, this.collateral.make);
                    }
                } catch (error) {
                    return toast.error(error);
                }
            },
            async populateYearOptionsAsync() {
                try {
                    this.populatingOptions = true;
                    // Populating the years means we have to invalidate every option afterwards
                    // since they all require the year
                    this.options.years = [];
                    this.options.makes = [];
                    this.options.models = [];
                    const request = {
                        collateralValuationType: this.collateralValuationType
                    };
                    const response = await this.getYearOptionsAsync(request);
                    this.options.years = response.options.map(yearOption => ({
                        label: yearOption.value,
                        value: yearOption.value
                    }));
                    this.populatingOptions = false;
                } catch (error) {
                    return toast.error(error);
                }
            },
            async populateMakeOptionsAsync(year) {
                try {
                    this.populatingOptions = true;
                    // The options for makes will change, which invalidates the next options
                    // which require a make
                    this.options.makes = [];
                    this.options.models = [];
                    const request = {
                        collateralValuationType: this.collateralValuationType,
                        year
                    };
                    const response = await this.getMakeOptionsAsync(request);
                    this.options.makes = response.options.map(makeOption => ({
                        label: makeOption.value,
                        value: makeOption.value,
                        condition: makeOption.collateralCondition
                    }));

                    //Lending app keeps values uppercased which won't match values from Collateral Valuation. Don't need case sensitivity here.
                    if (this.collateral.make) {
                        let firstMatch = this.options.makes.find(option => option.value.toLowerCase() == this.collateral.make.toLowerCase());
                        if (firstMatch) {
                            this.collateral.make = firstMatch.value;
                        }
                    }

                    this.populatingOptions = false;
                } catch (error) {
                    return toast.error(error);
                }
            },
            async populateModelOptionsAsync(year, make) {
                try {
                    this.populatingOptions = true;
                    // The options for models will change, which invalidates the next options
                    // which require a model
                    this.options.models = [];
                    const request = {
                        collateralValuationType: this.collateralValuationType,
                        year,
                        make
                    };
                    const response = await this.getModelOptionsAsync(request);
                    this.options.models = response.options.map(modelOption => ({
                        label: modelOption.value,
                        value: modelOption.value,
                        condition: modelOption.collateralCondition
                    }));

                    if (this.collateral.model) {
                        let firstMatch = this.options.models.find(option => option.value.toLowerCase() == this.collateral.model.toLowerCase());
                        if (firstMatch) {
                            this.collateral.model = firstMatch.value;
                        }
                    }

                    this.populatingOptions = false;
                } catch (error) {
                    return toast.error(error);
                }
            },
            async resetMakesOptionsAsync() {
                try {
                    this.collateral.make = null;
                    await this.populateMakeOptionsAsync(this.collateral.year);
                } catch (error) {
                    return toast.error(error);
                }
            },
            async resetModelOptionsAsync() {
                try {
                    this.collateral.model = null;
                    await this.populateModelOptionsAsync(this.collateral.year, this.collateral.make);
                } catch (error) {
                    return toast.error(error);
                }
            },
            async handleInput(optionType) {
                try {
                    switch (optionType) {
                        case CollateralOptionType.YEAR:
                            await this.resetMakesOptionsAsync();
                            break;
                        case CollateralOptionType.MAKE:
                            await this.resetModelOptionsAsync();
                            break;
                        default:
                            return;
                    }
                } catch (error) {
                    return toast.error(error);
                }
            },
			async handleSubmit() {
				const isValid = await this.$refs.observer.validate();

				if (!isValid) {
					return toast.error('Collateral information is invalid.');
				}

				try {
					this.showSpinner({ submitting: true });

					return await this.submitCollateral();
				} catch (error) {
					this.showSpinner({ submitting: false });

					return toast.error(error);
				}
			},
			async submitCollateral() {
				const request = {
					applicationId: this.task.applicationId,
					taskId: this.task.id,
					collateral: this.collateral
				};

				await collateralAPI.createMotorcycleCollateral(request);

				this.showSpinner({ submitting: false });

				return this.$emit('submit');
			}
		}
	}
</script>
