<template>
	<transition name="fade">
		<section v-show="!loading" class="notification-task">
			<section class="link-container">
				<StsAccordion :title="title">
					<ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="handleSubmit()">
						<section>
							<StsVInput
								v-model="lien.name"
								:disabled="submitting"
								label="LIENHOLDER NAME"
								type="text"
								validationRules="required"
							/>

							<StsVInput
								v-model="lien.phoneNumber"
								:disabled="submitting"
								label="LIENHOLDER PHONE NUMBER"
								mask="(###) ###-####"
								type="tel"
								validationRules="required"
							/>

							<StsVInput
								v-model="lien.accountNumber"
								:disabled="submitting"
								label="ACCOUNT NUMBER"
								type="text"
								validationRules="required"
							/>

							<StsVInput
								v-model="lien.nameOnAccount"
								:disabled="submitting"
								label="NAME ON ACCOUNT"
								type="text"
								validationRules="required"
							/>

							<StsButton :disabled="invalid || submitting" button-type="submit" title="SUBMIT" />
						</section>
					</ValidationObserver>
				</StsAccordion>
			</section>
		</section>
	</transition>
</template>

<script>
	import { lienAPI } from '@/api';
	import { StsAccordion, StsButton } from '@/components/SwitchThink';
	import { StsUpload, StsVInput, StsVRadioGroup } from '@/components/SwitchThink/form';
	import showSpinner from '@/mixins/showSpinner';
	import { ruleBuilder, toastConfig } from '@/utils';
	import { ValidationObserver } from 'vee-validate';
	import Component, { mixins } from 'vue-class-component';
	import { createToastInterface } from 'vue-toastification';

	const toast = createToastInterface(toastConfig);
	ruleBuilder(['required', 'digits']);

	@Component({
		components: {
			StsAccordion,
			StsButton,
			StsVInput,
			StsUpload,
			StsVRadioGroup,
			ValidationObserver
		},
		props: {
			moduleName: {
				type: String,
				required: true
			},
			/** @type {task} */
			task: {
				type: Object,
				required: true
			}
		}
	})
	class LienholderInfoTask extends mixins(showSpinner) {
		// Data properties
		loading = false;
		title = 'Lienholder Info';
		/** @type {lien} */
		lien = {
			name: '',
			phoneNumber: '',
			accountNumber: '',
			nameOnAccount: ''
		};

		async created() {
			try {
				this.showSpinner({ loading: true });

                const applicationId = this.task.applicationId;
                const currentLien = await lienAPI.getLien(applicationId);

                if (currentLien && currentLien.lien) {
					this.lien.name = currentLien.lien.name;
					this.lien.phoneNumber = currentLien.lien.phoneNumber;
					this.lien.accountNumber = currentLien.lien.accountNumber;
					this.lien.nameOnAccount = currentLien.lien.nameOnAccount;
				}

				this.showSpinner({ loading: false });
			} catch (error) {
				this.showSpinner({ loading: false });
				toast.error(error);
			}
		}

		async handleSubmit() {
			const isValid = await this.$refs.observer.validate();

			if (!isValid) {
				return toast.error('Lienholder information is invalid.');
			}

			try {
				this.showSpinner({ submitting: true });

				return await this.submitLien();
			} catch (error) {
				this.showSpinner({ submitting: false });

				return toast.error(error);
			}
		}

		async submitLien() {
			/** @type {lienCreateViewModelRequest} */
			const request = {
				taskId: this.task.id,
				lien: this.lien
			};

			await lienAPI.createLien(request);

			this.showSpinner({ submitting: false });

			return this.$emit('submit');
		}
	}

	export default LienholderInfoTask;
</script>
