<template>
	<transition name="fade">
		<section v-show="!loading" class="completed-task">
			<div class="icon">
				<BaseIcon :name="icon.name" height="48" width="48">
					<component :is="icon.component" />
				</BaseIcon>

				<p class="title">{{ model.title }}</p>
			</div>
		</section>
	</transition>
</template>

<script>
import { StsIconCard } from '@/components/SwitchThink';
import { BaseIcon, Check } from '@/components/SwitchThink/icons';
import showSpinner from '@/mixins/showSpinner';
import { toastConfig } from '@/utils';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';

const toast = createToastInterface(toastConfig);

export default Vue.extend({
	name: 'CompletedTask',
	components: {
		StsIconCard,
		Check,
		BaseIcon
	},
	mixins: [showSpinner],
	props: {
		moduleName: {
			type: String,
			required: true
		},
		model: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			icon: {
				name: 'check',
				component: Check
			}
		};
	},
	async created() {
		try {
			this.showSpinner({ loading: true });

			return this.showSpinner({ loading: false });
		} catch (error) {
			this.showSpinner({ submitting: false });
			return toast.error(error);
		}
	}
});
</script>

<style lang="scss" scoped>
.completed-task {
	.icon {
		background: none;
		border: none;
		padding: 0 0.75rem;
		margin: 0;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #70b603;
	}

	.title {
		flex: 1;
		margin: 0;
		padding: 0;
		margin-left: 1rem;
		font-size: 1rem;
		text-align: left;
		color: #333333;
	}

	@media only screen and (min-width: 850px) {
		.title {
			font-size: 0.85rem;
		}
	}
}
</style>
