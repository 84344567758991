<template>
	<transition name="fade">
		<section v-show="!loading" class="notification-task">
			<section class="link-container">
				<StsAccordion :title="title">
					<ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="handleSubmit()">
						<section>
							<StsVTextArea
								v-model="noteText"
								:disabled="submitting"
                                :tooltip="{
						title: 'Additional Comments',
						description: `Feel free to add any additional feedback you would like to include.`
					}"
								label="ADDITIONAL COMMENTS (OPTIONAL)"
								type="textarea"
								validationRules="required|max:1024"
							/>

							<StsButton :disabled="invalid || submitting" button-type="submit" title="SUBMIT" />
						</section>
					</ValidationObserver>
				</StsAccordion>
			</section>
		</section>
	</transition>
</template>

<script>
	import { StsAccordion, StsButton } from '@/components/SwitchThink';
	import { ruleBuilder, toastConfig } from '@/utils';
	import { StsVTextArea } from '@/components/SwitchThink/form';
	import { ValidationObserver } from 'vee-validate';
	import { createToastInterface } from 'vue-toastification';
	import { noteAPI } from '@/api';
	import showSpinner from '@/mixins/showSpinner';

	ruleBuilder(['required', 'digits', 'max']);
	const toast = createToastInterface(toastConfig);

	export default {
		name: 'NoteTask',
		components: {
			StsAccordion,
			StsButton,
			StsVTextArea,
			ValidationObserver
		},
		mixins: [showSpinner],
		props: {
			moduleName: {
				type: String,
				required: true
			},
			/** @type {task} */
			task: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				title: 'Additional Comments (Optional)',
				noteText: ''
			};
		},
		async created() {
			try {
				this.showSpinner({ loading: true });

				this.showSpinner({ loading: false });
			} catch (error) {
				this.showSpinner({ loading: false });
				toast.error(error);
			}
		},
		methods: {
			async handleSubmit() {
				const isValid = await this.$refs.observer.validate();

				if (!isValid) {
					return toast.error('Additional comment is invalid.');
				}

				try {
					this.showSpinner({ submitting: true });

					return await this.submitNote();
				} catch (error) {
					this.showSpinner({ submitting: false });

					return toast.error(error);
				}
			},
			async submitNote() {
				/** @type {noteCreateViewModelRequest} */
				const request = {
					applicationId: this.task.applicationId,
					noteText: this.noteText
				};

				await noteAPI.createNote(request);

				this.showSpinner({ submitting: false });

				return this.$emit('submit');
			}
		}
	};
</script>
