<template>
	<transition-group name="fade">
		<DuplicateUploadModal
			:title="ModalTitle"
			:message="DuplicateMessage"
			cancelText="Refresh Documents"
			:secondaryMessage="secondaryMessage"
			@close="cancelUpload"
			:preventClose="preventCloseModal"
			isSmall
			key="modal"
			v-if="showDuplicateModal"
		/>
		<section v-show="!loading" class="upload-task" key="mainsection">
			<StsAccordion :title="model.title">
				<section class="upload-container">
					<StsUpload
						:allowMultiple="true"
						:name="`upload-${moduleName}`"
						acceptedFiles="bmp png jpeg pdf tiff"
						:maxUploadSizeMB="22"
						@save="handleUpload"
					/>
				</section>

				<section class="note-container">
					<p><strong>Please note</strong></p>
					<p>Only the following file types are support BMP, PNG, JPG, PDF, TIF.</p>
					<p>The maximum file upload size is 22MB.</p>
				</section>
			</StsAccordion>
		</section>
	</transition-group>
</template>

<script>
	import { documentAPI } from '@/api';
	import { StsAccordion } from '@/components/SwitchThink';
	import { DuplicateUploadModal } from '@/components';
	import { StsUpload } from '@/components/SwitchThink/form';
	import showSpinner from '@/mixins/showSpinner';
	import { toastConfig } from '@/utils';
	import { createToastInterface } from 'vue-toastification';	
	import axios from 'axios';
	const toast = createToastInterface(toastConfig);
	export default {
		name: 'UploadTask',
		components: {
			StsAccordion,
			StsUpload,
			DuplicateUploadModal
		},
		mixins: [showSpinner],
		props: {
			moduleName: {
				type: String,
				required: true
			},
			model: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				showDuplicateModal: false,
				preventCloseModal: true,
				ModalTitle: "Duplicate Detected",
				secondaryMessage:"",
				DuplicateMessage : "We already have this/these documents on file.  Select a new file or click to refresh the page."
			};
		},
		async created() {
			try {
				this.showSpinner({ loading: true });				
				const {
					data: { defaultPhoneNumber }
				} = await axios.get('/api/Configuration/DefaultPhoneNumber');
				this.secondaryMessage = "If you continue to receive this message, please contact your loan specialist directly or reach us at " + defaultPhoneNumber+".";
				return this.showSpinner({ loading: false });
			} catch (error) {
				this.showSpinner({ submitting: false });
				return toast.error(error);
			}
		},
		methods: {
			async cancelUpload() {
				this.preventCloseModal = false;
				this.$emit("refresh");
				this.reload = false;
			},
			async handleUpload({ data, fileCount }) {
				try {
					this.showSpinner({ loading: true });
					const { posted, resultType } = await documentAPI.uploadDocuments(
						this.model.applicationId,
						this.model.id,
						this.$store.state.applicant.applicantToken,
						data
					);
					this.showSpinner({ submitting: false });
					//If documents were handled properly on backend
					if (posted) {						
						//1 no Duplicates
						if (resultType === 1 || resultType === 2) {
							return this.$emit('submit');
						//3 all Duplicates
						} else if (resultType === 3) {
							//Do we want them to upload more files or just refresh the page??
							this.DuplicateMessage = "We already have these documents on file. Click to refresh the page."
							this.showDuplicateModal = true;
						//0 Stip already complete
						}else if (resultType === 4) {
							//Do we want them to upload more files or just refresh the page??
							this.ModalTitle = "Task Completed"
							this.DuplicateMessage = "You have already completed this task. Click to refresh the page."
							this.showDuplicateModal = true;
						//0 none
						} else {							
							return toast.error('Error encountered while uploading, please try again.');
						}
					}else {
						return toast.error('Error encountered while uploading, please try again.');
					}
				} catch (error) {
					this.showSpinner({ submitting: false });
					return toast.error(error);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.upload-task {
	}
</style>
