<template>
	<transition name="fade">
		<section v-show="!loading" class="notification-task">
			<section class="link-container">
				<StsAccordion :title="title">
					<ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="handleSubmit()">
                        <StsCard v-if="isValuationEnabled" style="margin-bottom:1.5rem" noShadow>

                            <StsVSelect v-model="collateral.vehicleType"
                                        :disabled="submitting"
                                        :options="rvTypes"
                                        label="TYPE"
                                        validationRules="required" 
                                        @input="handleInput(CollateralOptionType.TYPE)" />

                            <StsVSelect v-if="showYears"
                                        v-model.number="collateral.year"
                                        :disabled="submitting"
                                        :options="options.years"
                                        label="Year"
                                        placeholder="Please select the year"
                                        validationRules="required"
                                        @input="handleInput(CollateralOptionType.YEAR)" />

                            <StsVSelect v-if="showMakes"
                                        v-model="collateral.make"
                                        :disabled="submitting"
                                        :options="options.makes"
                                        :placeholder="collateral.year ? 'Please select the make' : ''"
                                        label="Make"
                                        validationRules="required"
                                        @input="handleInput(CollateralOptionType.MAKE)" />

                            <StsVSelect v-if="showModels"
                                        v-model="collateral.model"
                                        :disabled="submitting"
                                        :options="options.models"
                                        :placeholder="collateral.make ? 'Please select the model' : ''"
                                        label="Model"
                                        validationRules="required"
                                        @input="handleInput(CollateralOptionType.MODEL)" />

                            <StsVInput v-if="showAdditionalFields && mileageRequired"
                                       v-model.number="collateral.mileage"
                                       label="Exact Mileage"
                                       :disabled="submitting"
                                       mask="######"
                                       type="tel"
                                       validationRules="required" />

                            <StsVInput v-if="showAdditionalFields"
                                       v-model="collateral.vin"
                                       :disabled="submitting"
                                       label="VIN"
                                       type="text"
                                       validationRules="required"
                                       forceCapitalization />

                            <StsVSelect v-if='showAdditionalFields && fuelRequired'
                                        v-model='collateral.fuelType'
                                        :disabled='submitting'
                                        :options='fuelTypes'
                                        :validationRules='fuelValidation'
                                        label='Fuel Type'
                                        mask='#####' />

                            <BaseIcon v-if="populatingOptions"
                                      height="1rem"
                                      name="populatingOptionsClock"
                                      viewBox="0 0 50 50"
                                      width="1rem">
                                <Clock />
                            </BaseIcon>

                        </StsCard>                        
						<StsCard v-if="!isValuationEnabled" style="margin-bottom: 1.5rem" noShadow>
						    <StsVSelect
						    	v-model="collateral.vehicleType"
						    	:disabled="submitting"
						    	:options="rvTypes"
						    	label="TYPE"
						    	validationRules="required"
						    />

						    <StsVInput
						    	v-model="collateral.year"
						    	:disabled="submitting"
						    	label="YEAR"
						    	mask="####"
						    	type="tel"
						    	validationRules="required|digits:4|min_value:1900"
						    />

						    <StsVInput
                                v-model="collateral.make"
                                :disabled="submitting"
                                label="MAKE"
                                type="text"
                                validationRules='required'
                            />

                            <StsVInput
                                v-model='collateral.model'
                                :disabled='submitting'
                                label='MODEL'
                                type='text'
                                validationRules='required'
                            />

                            <StsVInput
                                v-if='mileageRequired'
                                v-model='collateral.mileage'
                                :disabled='submitting'
                                :validationRules='mileageValidation'
                                label='Exact Mileage'
                                type='tel'
                                mask='######'
                            />

                            <StsVInput
                                v-model='collateral.vin'
                                :disabled='submitting'
                                label='VIN'
                                type='text'
                                validationRules='required'
						    	forceCapitalization
                            />

                            <StsVSelect
                                v-if='fuelRequired'
                                v-model='collateral.fuelType'
                                :disabled='submitting'
                                :options='fuelTypes'
                                :validationRules='fuelValidation'
                                label='Fuel Type'
                                mask='#####'
                            />
                        </StsCard>

						<StsButton :disabled="invalid || submitting || !allOptionsSelected" button-type="submit" title="SUBMIT" />
					</ValidationObserver>
				</StsAccordion>
			</section>
		</section>
	</transition>
</template>

<script>
	import { collateralAPI } from '@/api';
    import { StsAccordion, StsButton, StsCard } from '@/components/SwitchThink';
    import { BaseIcon, Clock } from '@/components/SwitchThink/icons';
    import { CollateralOptionType, CollateralValuationType, ProductType } from '@/constants';
    import { collateralValuation, showSpinner } from '@/mixins';
    import { collateralOptions } from '@/store/modules';
	import { StsVInput, StsVSelect } from '@/components/SwitchThink/form';
	import { ruleBuilder, toastConfig } from '@/utils';
	import { ValidationObserver } from 'vee-validate';
    import { createToastInterface } from 'vue-toastification';
    import { mapActions } from 'vuex';

	const toast = createToastInterface(toastConfig);
	ruleBuilder(['required', 'digits', 'min_value']);

    export default {
        name: 'AddRVCollateralTask',
        components: {
            BaseIcon,
            Clock,
            StsCard,
			StsAccordion,
			StsButton,
			StsVInput,
			StsVSelect,
			ValidationObserver
        },
        mixins: [collateralValuation, showSpinner],
		props: {
			moduleName: {
				type: String,
				required: true
			},
			/** @type {task} */
			task: {
				type: Object,
				required: true
			}
        },
        data() {
            return {
                // Data properties
                loading: false,
                title: 'Add Collateral',
                collateralValuationType: null,
                mileageMessage: 'Exact Mileage',
                mileageRequired: false,
                mileageValidation: '', //required for motorhome
                fuelTypes: [],
                fuelRequired: false,
                fuelValidation: '',
                rvTypes: [],
                collateral: {
                    year: null,
                    make: null,
                    model: null,
                    vin: null,
                    mileage: null,
                    vehicleType: null,
                    fuelType: null
                },
                options: {
                    years: [],
                    makes: [],
                    models: [],
                }
            }
        },
        computed: {
            CollateralOptionType() {
                return CollateralOptionType;
            },
            allOptionsSelected() {
                if (!this.isValuationEnabled) {
                    return true;
                }

                return this.collateral.vehicleType && this.collateral.year && this.collateral.make && this.collateral.model;
            },
            isValuationEnabled() {
                return this.valuationEnabled(ProductType.RV);
            },
            showAdditionalFields() {
                return this.allOptionsSelected;
            },
            showYears() {
                return this.options.years.length;
            },
            showMakes() {
                return this.options.makes.length;
            },
            showModels() {
                return this.options.models.length && this.showMakes;
            }
        },
		watch: {
			'collateral.vehicleType'(newType) {
				if (this.rvTypes[newType - 1].mileageRequired === true) {
                    this.mileageMessage = 'Exact Mileage';
                    this.mileageRequired = true;
                    this.mileageValidation = 'required';
                    this.fuelRequired = true;
                    this.fuelValidation = 'required';
                    this.$emit('changedState', this.state);
                } else {
                    this.mileageMessage = 'Estimated Mileage';
                    this.mileageRequired = false;
                    this.mileageValidation = '';
                    this.fuelRequired = false;
                    this.fuelValidation = '';
                    this.$emit('changedState', this.state);
                }
			}
		},
		async created() {
			try {
				this.showSpinner({ loading: true });

                if (!this.$store.hasModule('collateralOptions')) {
                    this.$store.registerModule('collateralOptions', collateralOptions);
                }
                this.collateralValuationType = CollateralValuationType.RV;

				const config = await collateralAPI.getRvCollateralOptions();

				this.rvTypes = config.rvTypes.map(type => ({
                    label: type.displayText,
                    value: type.id,
                    mileageRequired: type.requiresMileage
                }));

                this.fuelTypes = config.fuelTypes.map(type => ({
                    label: type.displayText,
                    value: type.id
                }));

                const applicationId = this.task.applicationId;

                const currentCollateral = await collateralAPI.getRVLoanApplicationCollateral(applicationId);

                if (currentCollateral && currentCollateral.rvCollateral) {
                    this.collateral.make = currentCollateral.rvCollateral.make;
                    this.collateral.model = currentCollateral.rvCollateral.model;
                    this.collateral.year = currentCollateral.rvCollateral.year;
                    this.collateral.mileage = currentCollateral.rvCollateral.mileage;
                    this.collateral.vin = currentCollateral.rvCollateral.vin;

                    if (currentCollateral.rvCollateral.vehicleType > 0) {
                        this.collateral.vehicleType = currentCollateral.rvCollateral.vehicleType;
                    }

                    if (currentCollateral.rvCollateral.fuelType > 0) {
                        this.collateral.fuelType = currentCollateral.rvCollateral.fuelType;
                    }
                }

                await this.initializeOptionsAsync();

                this.showSpinner({ loading: false });
            } catch (error) {
                this.showSpinner({ loading: false });
                toast.error(error);
            }
        },
        methods: {
            ...mapActions('collateralOptions', [
                'getYearOptionsAsync',
                'getMakeOptionsAsync',
                'getModelOptionsAsync',
            ]),
            async initializeOptionsAsync() {
                try {
                    if (!this.isValuationEnabled) {
                        return;
                    }


                    // Since years are reliant on RV Type, we can't prepopulate them.
                    // Let's populate the options using any values we already have
                    if (this.collateral.vehicleType) {
                        await this.populateYearOptionsAsync();
                    }

                    // Let's populate the options using any values we already have
                    if (this.collateral.year) {
                        await this.populateMakeOptionsAsync(this.collateral.year);
                    }

                    if (this.collateral.make) {
                        await this.populateModelOptionsAsync(this.collateral.year, this.collateral.make);
                    }

                } catch (error) {
                    return toast.error(error);
                }
            },
            async populateYearOptionsAsync() {
                try {
                    this.populatingOptions = true;

                    // Populating the years means we have to invalidate every option afterwards
                    // since they all require the year
                    this.options.years = [];
                    this.options.makes = [];
                    this.options.models = [];

                    const request = {
                        collateralValuationType: this.collateralValuationType,
                        rvCategoryId: this.collateral.vehicleType
                    };

                    const response = await this.getYearOptionsAsync(request);

                    this.options.years = response.options.map(yearOption => ({
                        label: yearOption.value,
                        value: yearOption.value
                    }));

                    this.populatingOptions = false;
                } catch (error) {
                    return toast.error(error);
                }
            },
            async populateMakeOptionsAsync(year) {
                try {
                    this.populatingOptions = true;

                    // The options for makes will change, which invalidates the next options
                    // which require a make
                    this.options.makes = [];
                    this.options.models = [];

                    const request = {
                        collateralValuationType: this.collateralValuationType,
                        year,
                        rvCategoryId: this.collateral.vehicleType
                    };

                    const response = await this.getMakeOptionsAsync(request);

                    this.options.makes = response.options.map(makeOption => ({
                        label: makeOption.value,
                        value: makeOption.value,
                        condition: makeOption.collateralCondition
                    }));

                    //Lending app keeps values uppercased which won't match values from Collateral Valuation. Don't need case sensitivity here.
                    if (this.collateral.make) {
                        let firstMatch = this.options.makes.find(option => option.value.toLowerCase() == this.collateral.make.toLowerCase());
                        if (firstMatch) {
                            this.collateral.make = firstMatch.value;
                        }
                    }

                    this.populatingOptions = false;
                } catch (error) {
                    return toast.error(error);
                }
            },
            async populateModelOptionsAsync(year, make) {
                try {
                    this.populatingOptions = true;

                    // The options for models will change, which invalidates the next options
                    // which require a model
                    this.options.models = [];

                    const request = {
                        collateralValuationType: this.collateralValuationType,
                        year,
                        make,
                        rvCategoryId: this.collateral.vehicleType
                    };

                    const response = await this.getModelOptionsAsync(request);

                    this.options.models = response.options.map(modelOption => ({
                        label: modelOption.value,
                        value: modelOption.value,
                        condition: modelOption.collateralCondition
                    }));

                    if (this.collateral.model) {
                        let firstMatch = this.options.models.find(option => option.value.toLowerCase() == this.collateral.model.toLowerCase());
                        if (firstMatch) {
                            this.collateral.model = firstMatch.value;
                        }
                    }

                    this.populatingOptions = false;
                } catch (error) {
                    return toast.error(error);
                }
            },
            async resetYearsOptionsAsync() {
                try {
                    this.collateral.year = null;
                    await this.populateYearOptionsAsync();
                } catch (error) {
                    return toast.error(error);
                }
            },
            async resetMakesOptionsAsync() {
                try {
                    this.collateral.make = null;
                    await this.populateMakeOptionsAsync(this.collateral.year);
                } catch (error) {
                    return toast.error(error);
                }
            },
            async resetModelOptionsAsync() {
                try {
                    this.collateral.model = null;

                    await this.populateModelOptionsAsync(this.collateral.year, this.collateral.make);
                } catch (error) {
                    return toast.error(error);
                }
            },
            async handleInput(optionType) {
                try {
                    switch (optionType) {
                        case CollateralOptionType.TYPE:
                            await this.resetYearsOptionsAsync();
                            break;

                        case CollateralOptionType.YEAR:
                            await this.resetMakesOptionsAsync();
                            break;

                        case CollateralOptionType.MAKE:
                            await this.resetModelOptionsAsync();
                            break;

                        default:
                            return;
                    }
                } catch (error) {
                    return toast.error(error);
                }
            },
            clearFuelTypeIfNotRequired() {
                if (!this.fuelRequired) {
                    this.collateral.fuelType = null;
                }
            },
            clearMileageIfNotRequired() {
                if (!this.mileageRequired) 
                {
                    this.collateral.mileage = null;
                }
            },
            async handleSubmit() {
                const isValid = await this.$refs.observer.validate();

                if (!isValid) {
                    return toast.error('Collateral information is invalid.');
                }

                try {
                    this.showSpinner({ submitting: true });

                    this.clearFuelTypeIfNotRequired();
                    this.clearMileageIfNotRequired();

                    return await this.submitCollateral();
                } catch (error) {
                    this.showSpinner({ submitting: false });

                    return toast.error(error);
                }
            },
		    async submitCollateral() {
                /** @type {rvLoanApplicationUpdateViewModelRequest} */
                const request = {
                    applicationId: this.task.applicationId,
                    taskId: this.task.id,
                    collateral: this.collateral
                };

                await collateralAPI.createRVCollateral(request);

                this.showSpinner({ submitting: false });

                return this.$emit('submit');
            }
        }
    }
</script>
