<template>
  <transition name="fade">
    <section v-show="!loading" class="notification-task">
      <section class="link-container">
        <StsAccordion :title="title">
          <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="handleSubmit()">
            <section>
              <StsVInput
                v-model="insurance.insuranceAgency"
                :disabled="submitting"
                label="INSURANCE AGENCY"
                type="text"
                validationRules="required"
              />

              <StsVInput
                v-model="insurance.policyNumber"
                :disabled="submitting"
                label="POLICY NUMBER"
                type="text"
                validationRules="required"
              />

              <StsVInput
                v-model="insurance.agentName"
                :disabled="submitting"
                label="AGENT NAME (OPTIONAL)"
                type="text"
              />
              
              <StsVInput
                v-model="insurance.phoneNumber"
                :disabled="submitting"
                label="PHONE NUMBER (OPTIONAL)"
                mask="(###) ###-####"
			    type="tel"
              />

              <StsButton :disabled="invalid || submitting" button-type="submit" title="SUBMIT" />
            </section>
          </ValidationObserver>
        </StsAccordion>
      </section>
    </section>
  </transition>
</template>

<script>
import { insuranceAPI } from "@/api";
import { StsAccordion, StsButton } from "@/components/SwitchThink";
import { StsUpload, StsVInput, StsVRadioGroup } from "@/components/SwitchThink/form";
import showSpinner from "@/mixins/showSpinner";
import { ruleBuilder } from "@/utils";
import { ValidationObserver } from "vee-validate";
import Component, { mixins } from "vue-class-component";


ruleBuilder(["required", "digits"]);

@Component({
    components: {
        StsAccordion,
        StsButton,
        StsVInput,
        StsUpload,
        StsVRadioGroup,
        ValidationObserver
    },
    props: {
        moduleName: {
            type: String,
            required: true
        },
        /** @type {task} */
        task: {
            type: Object,
            required: true
        }
    }
})

class InsuranceInfoTask extends mixins(showSpinner) {
    // Data properties
    loading = false;
    title = "Proof of Insurance";
    /** @type {insurance} */
    insurance = {
        insuranceAgency: "",
        policyNumber: "",
        agentName: "",
        phoneNumber: ""
    };
    
    async created() {
        try {
            this.showSpinner({ loading: true });
    
            const applicationId = this.task.applicationId;
            const currentInsurance = await insuranceAPI.getInsurance(applicationId);
    
            if (currentInsurance && currentInsurance.insurance) {
                this.insurance.insuranceAgency = currentInsurance.insurance.insuranceAgency;
                this.insurance.policyNumber = currentInsurance.insurance.policyNumber;
                this.insurance.agentName = currentInsurance.insurance.agentName;
                this.insurance.phoneNumber = currentInsurance.insurance.phoneNumber;
            }
    
            this.showSpinner({ loading: false });
        } catch (error) {
            this.showSpinner({ loading: false });
            toast.error(error);
        }
    }
    
    async handleSubmit() {
        const isValid = await this.$refs.observer.validate();
    
        if (!isValid) {
            return toast.error("Insurance information is invalid.");
        }
    
        try {
            this.showSpinner({ submitting: true });
    
            return await this.submitInsurance();
        } catch (error) {
            this.showSpinner({ submitting: false });
    
            return toast.error(error);
        }
    }
    
    async submitInsurance() {
        /** @type {insuranceCreateViewModelRequest} */
        const request = {
            taskId: this.task.id,
            insurance: this.insurance
        };
    
        await insuranceAPI.createInsurance(request);
    
        this.showSpinner({ submitting: false });
    
        return this.$emit("submit");
    }
}

export default InsuranceInfoTask;
</script>
